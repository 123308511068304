import { Custom404, CustomErrorPage } from '@/components';
import { AmmaContextProvider, AuthContextProvider, TeamsContextProvider } from '@/contexts';
import {
  ApplicationPage,
  ApplicationsPage,
  GetStartedPage,
  Home,
  ProductPage,
  ProductRawSpecificationPage,
  ProductsPage,
  ProductSpecificationPage,
} from '@/pages';
import { initializeSentry, msalInstance } from '@/utils';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import { captureException, ErrorBoundary } from '@sentry/react';
import { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

// eslint-disable-next-line import/no-unassigned-import, import/extensions
import '@/styles/globals.scss';
// eslint-disable-next-line import/no-unassigned-import
import '@lego/connect-theme-enterprise';

export const App: FC = () => {
  useEffect(() => {
    initializeSentry();
  }, []);

  const handleError = (error: unknown, componentStack?: string) => {
    captureException(error, { extra: { componentStack } });
  };

  const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          fontFamily: 'unset',
          color: 'var(--text-primary)',
        },
        '*, *::before, *::after': {
          borderColor: 'var(--border-primary)',
        },
      }),
    },
    initialColorMode: 'light',
    useSystemColorMode: false,
  });

  return (
    <ErrorBoundary fallback={<CustomErrorPage />} onError={handleError}>
      <MsalProvider instance={msalInstance}>
        <AuthContextProvider>
          <ChakraProvider theme={theme}>
            <AmmaContextProvider>
              <TeamsContextProvider>
                <ColorModeScript initialColorMode="light" />
                <Routes>
                  <Route element={<Home />} path="/" />
                  <Route element={<GetStartedPage />} path="/get-started" />

                  {/* Applications section */}
                  <Route element={<ApplicationsPage />} path="/applications" />
                  <Route element={<ApplicationPage />} path="/applications/:applicationName" />

                  {/* Teams section */}
                  {/* <Route element={<TeamsPage />} path="/teams" />
                  <Route element={<TeamPage />} path="/teams/:teamName" />
                  <Route
                    element={<InviteExternalPartnerPage />}
                    path="/teams/:teamName/invite-external-partner"
                  />
                  <Route element={<CreateTeamPage />} path="/teams/create" /> */}

                  {/* Products section */}
                  <Route element={<ProductsPage />} path="/products" />
                  <Route element={<ProductPage />} path="/products/:productName" />
                  {/* <Route element={<ProductApplicationsPage />} path="/products/:productName/applications" /> */}
                  {/* <Route
                    element={<ProductDocumentationPage />}
                    path="/products/:productName/documentation"
                  /> */}
                  <Route
                    element={<ProductSpecificationPage />}
                    path="/products/:productName/specification"
                  />
                  <Route
                    element={<ProductRawSpecificationPage />}
                    path="/products/:productName/raw-specification"
                  />

                  <Route element={<Custom404 />} path="*" />
                </Routes>
              </TeamsContextProvider>
            </AmmaContextProvider>
          </ChakraProvider>
        </AuthContextProvider>
      </MsalProvider>
    </ErrorBoundary>
  );
};
