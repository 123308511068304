import { AzureCloudInstance, Configuration, PublicClientApplication } from '@azure/msal-browser';

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
export const authScopes = [`api://${process.env.LEAPI_CLIENT_ID}/user_impersonation`];

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.CLIENT_ID ?? '',
    authority: 'https://login.microsoftonline.com/legogroup.onmicrosoft.com',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
    azureCloudOptions: {
      azureCloudInstance: AzureCloudInstance.AzurePublic,
      tenant: process.env.TENANT_ID,
    },
    OIDCOptions: {
      defaultScopes: authScopes,
    },
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
