/* eslint-disable no-console */
import { authScopes, msalInstance } from '@/utils';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';

export const apiClient = axios.create({
  baseURL: `${process.env.LEAPI_BASE_URL ?? ''}/api`,
});

apiClient.interceptors.request.use(async (request) => {
  const account = msalInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: authScopes,
    account,
  };

  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(accessTokenRequest);
    request.headers.Authorization = `Bearer ${accessToken}`;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(accessTokenRequest);
    } else {
      throw error;
    }
  } finally {
    return request;
  }
});
