import { getApplications, getProducts } from '@/services';
import { IAmmaApi, IAmmaApplication, IAmmaContext } from '@/types';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from './AuthContext';

export const AmmaContext = createContext<IAmmaContext>({} as IAmmaContext);

export const AmmaContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [products, setProducts] = useState<IAmmaApi[]>([]);
  const [applications, setApplications] = useState<IAmmaApplication[]>([]);
  const { isAuthenticated } = useAuthContext();

  const getData = useCallback(async () => {
    try {
      const [productsData, applicationsData] = await Promise.all([
        getProducts(),
        getApplications(),
      ]);

      setProducts(productsData);
      setApplications(applicationsData);
    } catch (error) {
      throw error;
    }
  }, []);

  const createApplication = () => {
    // eslint-disable-next-line no-console
    console.log('create application clicked');
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    void getData();
  }, [isAuthenticated, getData]);

  const value: IAmmaContext = useMemo(
    () => ({
      products,
      applications,
      createApplication,
    }),
    [products, applications],
  );

  return <AmmaContext.Provider value={value}>{children}</AmmaContext.Provider>;
};

export const useAmmaContext: () => IAmmaContext = () => useContext(AmmaContext);
