import { IAmmaApi, IAmmaWorkspace } from '@/types';
import workspaces from '../data/ammaWorkspaces.json';

export const getProducts = async (): Promise<IAmmaApi[]> => {
  try {
    // const response = await axiosInstance.get('/api/v1/account/me');
    const ammaWorkspaces = workspaces as IAmmaWorkspace[];

    return Promise.resolve(
      ammaWorkspaces
        .flatMap((workspace) => workspace.apis)
        // assume that only APIs have specifications, applications will not
        .filter((api) => api.specifications.length > 0),
    );
  } catch (error) {
    throw error;
  }
};
