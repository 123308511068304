import { LEGOLogo, NavbarAvatar, NavbarDrawer, NavbarLink, Typography } from '@/components';
import { HStack, IconButton, Stack, useDisclosure } from '@chakra-ui/react';
import { Icon } from '@lego/connect-components-react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.scss';

export const Navbar: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack align="center" bg="var(--background-accent)" px={4}>
      <HStack justifyContent="space-between" maxW="100rem" p={4} w="100%">
        <Link to="/">
          <HStack cursor="pointer" spacing={4}>
            <LEGOLogo style={{ height: '60px' }} />
            <Typography className={styles.navbarItem} size="lg">
              Developer Portal
            </Typography>
          </HStack>
        </Link>
        <HStack display={{ base: 'none', md: 'flex' }} spacing={12}>
          <NavbarLink href="/get-started" title="Get started" />
          <NavbarLink href="/products" title="Products" />
          <NavbarLink href="/applications" title="Applications" />
          {/* <NavbarLink href="/teams" title="Teams" /> */}
          <NavbarAvatar />
        </HStack>
        <IconButton
          aria-label="Open Menu"
          bg="none"
          display={{ base: 'flex', md: 'none' }}
          icon={<Icon ariaLabel="menu" name="bars-solid" />}
          onClick={onOpen}
        />
      </HStack>
      <NavbarDrawer isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};
