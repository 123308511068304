import { ProductPageContainer } from '@/components';
import { getSpec } from '@/services';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
// eslint-disable-next-line import/no-unassigned-import
import 'swagger-ui-react/swagger-ui.css';

export const ProductSpecificationPage: FC = () => {
  const { productName } = useParams();

  const [rawSpecification, setRawSpecification] = useState<string>('');
  const [rawSpecObject, setRawSpecObject] = useState<object>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    void loadSpec();
  }, [productName]);

  if (!productName) {
    return null;
  }

  const loadSpec = async () => {
    const rawSpec = await getSpec(productName);
    setRawSpecObject(rawSpec);
    setRawSpecification(JSON.stringify(rawSpec, undefined, 2));
  };

  return (
    <ProductPageContainer
      description={`${productName} specification`}
      title={`${productName} specification`}
    >
      {/* @ts-ignore issue with types*/}
      <SwaggerUI
        deepLinking={true}
        defaultModelsExpandDepth={3}
        requestSnippets={{}}
        requestSnippetsEnabled={true}
        spec={rawSpecObject}
        tryItOutEnabled={true}
      />
    </ProductPageContainer>
  );
};
