import * as Sentry from '@sentry/react';
// eslint-disable-next-line node/no-unpublished-import
import type { BaseTransportOptions, Client, ClientOptions } from '@sentry/types';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
import packageJson from '../../package.json';

export const initializeSentry = (): Client<ClientOptions<BaseTransportOptions>> | undefined => {
  const CrashReporter = Sentry.init({
    debug: false,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.breadcrumbsIntegration({ console: true }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    release: `LEAP@${packageJson.version}`,
    tracesSampleRate: 1.0,
    enabled: true,
    ignoreErrors: [
      'block_iframe_reload',
      'no_account_error',
      'post_request_failed',
      'monitor_window_timeout',
      'endpoints_resolution_error',
      'no_network_connectivity',
      'Network Error',
      'interaction_in_progress',
    ],
  });

  return CrashReporter;
};
